/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.validation-msg {
  display: none;
}

.ion-invalid .validation-msg {
  display: block;
}
ion-col{
    padding: 0;
}
ion-card-content {
    padding: 5px;
}
ion-card{
    margin:0;
}
ion-card-title, ion-card-subtitle {
    color: #bb8727;
}
.titleicon {
    position: absolute;
    width: 100%;
    text-align:left;
    margin-left: 5px;  
}
.round {
    --padding-start: 10px;
    --padding-end: 10px;
  }
  ion-button {
    vertical-align: middle;
  }
  .sc-ion-card-md-h {
      margin:0;
  }
  .sub-header {
    text-align: center;
    background: #000;
    padding: 5px;
    animation-name: text-color-gold-white;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  @keyframes text-color-gold-white {
    from {color: #bb8727;}
    to {color: #f7c99e;}
  }
  ion-input {
    border-bottom: 1px solid #bb8727;
}
.alert-button {
  background: RGBA(255,255,255,0.25);
  box-shadow: 0px 0px 3px #fff;
}
:focus {
    outline: 0px;
}
.custom-table {
  border-collapse: collapse;
  width: 100%;
  font-size: small; /* Set font size to small */
}

.custom-table th, .custom-table td {
  border: 1px solid #343232;
  padding: 8px;
  text-align: left;
}

