// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #9b5d46;
	--ion-color-primary-rgb: 155,93,70;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #88523e;
	--ion-color-primary-tint: #a56d59;

	--ion-color-secondary: #d09892;
	--ion-color-secondary-rgb: 208,152,146;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #b78680;
	--ion-color-secondary-tint: #d5a29d;

	--ion-color-tertiary: #6030ff;
	--ion-color-tertiary-rgb: 96,48,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #542ae0;
	--ion-color-tertiary-tint: #7045ff;

	--ion-color-success: #2dd55b;
	--ion-color-success-rgb: 45,213,91;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28bb50;
	--ion-color-success-tint: #42d96b;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #c5000f;
	--ion-color-danger-rgb: 197,0,15;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #ad000d;
	--ion-color-danger-tint: #cb1a27;

	--ion-color-light: #e6cfbc;
	--ion-color-light-rgb: 230,207,188;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #cab6a5;
	--ion-color-light-tint: #e9d4c3;

	--ion-color-medium: #5f5f5f;
	--ion-color-medium-rgb: 95,95,95;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #545454;
	--ion-color-medium-tint: #6f6f6f;

	--ion-color-dark: #2f2f2f;
	--ion-color-dark-rgb: 47,47,47;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #292929;
	--ion-color-dark-tint: #444444;


  --ion-background-color: #eddbd4;
  --ion-text-color: #9b5d46;
}
